
//import './styles/global.css';

exports.onRouteUpdate = ({ location }) => {
  const path = location.pathname;

  // /clinic にアクセスしている場合のみ、スラッシュを強制
  if (path === '/clinic' && window.location.pathname !== '/clinic/') {
    window.history.replaceState(null, '', '/clinic/');
  }
  // /clinic/sbc など、サブページにはスラッシュを付けない
  if (path.startsWith('/clinic/') && path !== '/clinic/') {
    window.history.replaceState(null, '', path.replace(/\/$/, ''));
  }

  // /techniques にアクセスしている場合のみ、スラッシュを強制
  if (path === '/techniques' && window.location.pathname !== '/techniques/') {
    window.history.replaceState(null, '', '/techniques/');
  }
  // /techniques/sbc など、サブページにはスラッシュを付けない
  if (path.startsWith('/techniques/') && path !== '/techniques/') {
    window.history.replaceState(null, '', path.replace(/\/$/, ''));
  }

  // /beauty にアクセスしている場合のみ、スラッシュを強制
  if (path === '/beauty' && window.location.pathname !== '/beauty/') {
    window.history.replaceState(null, '', '/beauty/');
  }
  // /beauty/sbc など、サブページにはスラッシュを付けない
  if (path.startsWith('/beauty/') && path !== '/beauty/') {
    window.history.replaceState(null, '', path.replace(/\/$/, ''));
  }

  // /faq にアクセスしている場合のみ、スラッシュを強制
  if (path === '/faq' && window.location.pathname !== '/faq/') {
    window.history.replaceState(null, '', '/faq/');
  }
  // /faq/sbc など、サブページにはスラッシュを付けない
  if (path.startsWith('/faq/') && path !== '/faq/') {
    window.history.replaceState(null, '', path.replace(/\/$/, ''));
  }

  // /area にアクセスしている場合のみ、スラッシュを強制
  if (path === '/area' && window.location.pathname !== '/area/') {
    window.history.replaceState(null, '', '/area/');
  }
  // /area/sbc など、サブページにはスラッシュを付けない
  if (path.startsWith('/area/') && path !== '/area/') {
    window.history.replaceState(null, '', path.replace(/\/$/, ''));
  }

};
